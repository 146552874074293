import * as Sentry from '@sentry/nextjs'
import React, { useEffect } from 'react'
import NextHead from 'next/head'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'
import config from '@/config/seo.json'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'photoswipe/dist/photoswipe.css'

import initializeSmileIo from '@/lib/smile-io/initialize'
import { useKustomer } from '@/hooks'

const Head: React.FC = () => {
  const { handleKustomerLoaded } = useKustomer()

  useEffect(() => {
    const handleSmileIOLoaded = async () => {
      initializeSmileIo()
    }

    document.addEventListener('smile-ui-loaded', handleSmileIOLoaded)
    return () => {
      document.removeEventListener('smile-ui-loaded', handleSmileIOLoaded)
    }
  }, [])

  return (
    <>
      <DefaultSeo {...config} />
      <NextHead key={'global'}>
        <meta name="apple-itunes-app" content="app-id=1485625002" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          key={'viewport'}
        />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
      </NextHead>
      <Script
        id={'payhelm'}
        src={
          'https://d99xz3flubf0x.cloudfront.net/js/payhelm.1.0.0.js?cart_api_url=%2Fapi%2Fcart'
        }
        strategy={'afterInteractive'}
        onError={Sentry.captureException}
      />
      <Script
        id={'kustomer'}
        src={'https://cdn.kustomerapp.com/chat-web/widget.js'}
        data-kustomer-api-key="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxOTQ3YWZjMjcxZDFjOGRiMzY4Mzg1NCIsInVzZXIiOiI2MTk0N2FmYTI1OGRjYWU0YjkxMzI3ODIiLCJvcmciOiI2MTgxODY5NTVjNGVjNGM4MDgzYjBkNjAiLCJvcmdOYW1lIjoibGluZGFmaW5lZ29sZCIsInVzZXJUeXBlIjoibWFjaGluZSIsInBvZCI6InByb2QxIiwicm9sZXMiOlsib3JnLnRyYWNraW5nIl0sImF1ZCI6InVybjpjb25zdW1lciIsImlzcyI6InVybjphcGkiLCJzdWIiOiI2MTk0N2FmYTI1OGRjYWU0YjkxMzI3ODIifQ.DUdGzEqIz4_MG86HoqMR86gKznd4AnnFAF5umVvRHBc"
        onLoad={handleKustomerLoaded}
        onError={Sentry.captureException}
      />

      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-346418533"
      />

      <Script
        id={'google-data-layer'}
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-H9S0010Z66');
            gtag('config', 'AW-346418533');
          `,
        }}
      />

      <Script
        id={'google-analytics'}
        dangerouslySetInnerHTML={{
          __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            
            ga('create', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', 'auto');
            ga('send', 'pageview');`,
        }}
        onError={Sentry.captureException}
      />

      <Script
        id={'hitsteps'}
        dangerouslySetInnerHTML={{
          __html: `
              (function(){var hstc=document.createElement('script'); hstc.src='https://cdnhst.xyz/code?code=0003a681e7cccd55c1440e1536899ced';hstc.async=true;var htssc = document.getElementsByTagName('script')[0];htssc.parentNode.insertBefore(hstc, htssc);})(); 
            `,
        }}
        onError={Sentry.captureException}
      />
      <Script
        src="https://js.smile.io/v1/smile-ui.js"
        onError={Sentry.captureException}
      />

      <Script
        key={'privy-business-identifier'}
        id={'privy-business-identifier'}
        dangerouslySetInnerHTML={{
          __html: `var _d_site = _d_site || '901FBD80F16361E825EE4E8F';`,
        }}
        onError={Sentry.captureException}
      />

      <Script
        defer
        key={'privy-widget'}
        id={'privy-widget'}
        src={'https://widget.privy.com/assets/widget.js'}
        onError={Sentry.captureException}
      />
    </>
  )
}

export default Head
