import React, { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import { SessionProvider } from 'next-auth/react'
import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'

import { Analytics } from '@vercel/analytics/react'
import { BareFetcher, SWRConfig } from 'swr'
import { ApolloProvider } from '@apollo/client'
import { builder } from '@builder.io/react'

import { CommerceProvider } from '@framework'
import Head from '@/components/Head'
import { storefrontClient } from '@/lib/apollo'

import {
  KirillsHousePartyContext,
  LiveStoryPlayerContext,
} from '@lindafinegold/kirills-house-party'
import '@lindafinegold/kirills-house-party/assets/styles.css'
import '@/assets/nprogress.css'
import '@/assets/global.css'

// import { MaintainanceModeView } from '@/features/maintainance-mode/views'
// import { Layout } from '@/components'
import { Session } from 'next-auth'
// import May8Gate from '@/features/maintainance-mode/views/May8Gate'
import { MaintainanceModeView } from '@/features/maintainance-mode/views'
import { useRouteChangeHandlers } from '@/hooks'

builder.init('54e650135bc5422bab7f7c70d4e77df4')
/* Register components with Builder
 * Using "require" here because "import" is eliminated from the code in production build.
 */
require('@/features/categories/components/CategoryProductTileSet')
require('@/features/categories/components/CategoryList')

export { reportWebVitals } from 'next-axiom'

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  const router = useRouter()

  useRouteChangeHandlers()

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  const fetcher: BareFetcher<any> = (resource, init) =>
    fetch(resource, init).then((res) => res.json())

  return (
    <>
      {/*/ TODO: Style the error boundary fallback */}
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <div className="flex flex-col items-center justify-center w-full h-screen space-y-4">
            <p>An unexpected error occurred:</p>
            <p>{error.toString()}</p>
            <p>{componentStack}</p>
            <button className={'button button--primary'} onClick={resetError}>
              Retry
            </button>
          </div>
        )}
        onError={(error) => {
          console.error(error)
        }}
      >
        <KirillsHousePartyContext.Provider
          value={{
            // TODO: Styles
            graphqlEndpoint:
              process.env.NEXT_PUBLIC_LINDAFINEGOLD_GRAPHQL_ENDPOINT ||
              'https://alf.fly.dev/graphql/',

            archiveUrl: '/kirills-house-party',
          }}
        >
          {/* TODO: Move to storefront settings? */}
          <LiveStoryPlayerContext.Provider
            value={{
              storyModeSelectorEnabled: false,
              temporaryStoryPlayerPortal:
                typeof window === 'undefined'
                  ? null
                  : document.querySelector(
                      '#temporary-alf-custom-portal-root-khp'
                    ),
            }}
          >
            <SessionProvider session={session}>
              {/* #34 CommerceProvider deprecated, will switch to our own SWRconfig and get rid of @vercel/commerce all together */}
              <CommerceProvider locale={router.locale}>
                <SWRConfig value={{ fetcher }}>
                  <ApolloProvider client={storefrontClient}>
                    <Head />
                    {process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' ? (
                      // <May8Gate />
                      <MaintainanceModeView />
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </ApolloProvider>
                </SWRConfig>
              </CommerceProvider>
            </SessionProvider>
          </LiveStoryPlayerContext.Provider>
        </KirillsHousePartyContext.Provider>
        <div
          id="temporary-alf-custom-portal-root-khp"
          className="kirills-house-party"
        />
      </Sentry.ErrorBoundary>
      <Analytics />
    </>
  )
}
